<template>
  <v-card>
    <v-card-text>
      <v-alert outlined type="warning">管理者権限がありません。管理者権限のあるIDでログインしてください。</v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="onSignOut">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { firebase } from '@/services/firebase'

export default {
  name: 'invalidAdmin',
  components: {
  },
  methods: {
    async onSignOut () {
      firebase.auth().signOut()
      this.$router.replace('/')
    }
  }
}
</script>
